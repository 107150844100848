// 时间格式化插件

// 补0
function addZero (m: any) {
  return m < 10 ? '0' + m : m
}
// 转换为yyyy-MM
export const monthFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    return year + '-' + addZero(month)
  } else {
    return ''
  }
}
// 转换为yyyy-MM-dd
export const dayFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const date = time.getDate()
    return year + '-' + addZero(month) + '-' + addZero(date)
  } else {
    return ''
  }
}
// 转换为MM-dd
export const monthDayFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const month = time.getMonth() + 1
    const date = time.getDate()
    return addZero(month) + '.' + addZero(date)
  } else {
    return ''
  }
}
// 转换为yyyy-MM-dd HH
export const hourFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const date = time.getDate()
    const hours = time.getHours()
    return year + '-' + addZero(month) + '-' + addZero(date) + ' ' + addZero(hours)
  } else {
    return ''
  }
}

// 转换为yyyy-MM-dd HH-mm
export const minuteFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const date = time.getDate()
    const hours = time.getHours()
    const minutes = time.getMinutes()
    return year + '-' + addZero(month) + '-' + addZero(date) + ' ' + addZero(hours) + ':' + addZero(minutes)
  } else {
    return ''
  }
}

// 转换为yyyy-MM-dd HH-mm-ss
export const timeFormat = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const date = time.getDate()
    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()
    return year + '-' + addZero(month) + '-' + addZero(date) + ' ' + addZero(hours) + ':' + addZero(minutes) + ':' + addZero(seconds)
  } else {
    return ''
  }
}

// 转换分钟为时分 mm to HH小时mm分钟
export const mmToHHmm = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    if (timestamp < 60) {
      return ' ' + timestamp + '分钟'
    } else {
      const hours = parseInt(String(timestamp / 60))
      const minutes = timestamp % 60
      return hours + '小时' + minutes + '分钟'
    }
  } else {
    return ''
  }
}
// 转换为yyyy年MM月dd日
export const dayFormatChinese = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const date = time.getDate()
    return year + '年' + addZero(month) + '月' + addZero(date) + '日'
  } else {
    return ''
  }
}

// 转换为 HH:mm:ss
export const formatDuring = (timestamp: any) => {
  if (timestamp && timestamp !== '' && timestamp !== null) {
    timestamp = typeof timestamp === 'number' ? timestamp : parseInt(timestamp)
    const time = new Date(timestamp)
    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()
    return addZero(hours) + ':' + addZero(minutes) + ':' + addZero(seconds)
  } else {
    return ''
  }
}

/**
 * 日期选择N天前
 * @param {number} day
 * @param {boolean} havetoday
 * @returns {any[]}
 */
export const dateBefore = (day: number, havetoday: boolean) => {
  const dateArr = []
  const date = new Date()
  if (!(day || '')) {
    day = 1
  }
  const date1 = new Date(date.getTime() - (!havetoday ? 24 * 60 * 60 * 1000 : 0))
  const date2 = new Date(date.getTime() - day * 24 * 60 * 60 * 1000)
  const val = date1.getFullYear() + '-' + addZero(date1.getMonth() + 1) + '-' + addZero(date1.getDate())
  const val2 = date2.getFullYear() + '-' + addZero(date2.getMonth() + 1) + '-' + addZero(date2.getDate())
  dateArr.push(val2, val)
  return dateArr
}
